import type { BlockTypes } from '@etf1-interne/tf1info_types_news'

import { useContext } from 'react'
import { theme } from '../../../styles'
import { Headline } from '../../atoms/Headline'
import { ArticleItem } from '../../atoms/ArticleItem'
import { ArticleListItem } from '../../atoms/ArticleListItem/ArticleListItem'
import { TagCommanderContext } from '../../../contexts/tagcommander'

export function ArticleList({
  elementList,
  title,
  isEnriched,
}: BlockTypes['article-list']['data']): JSX.Element {
  const { hit } = useContext(TagCommanderContext)
  const ArticleItemComponent = isEnriched ? ArticleListItem : ArticleItem

  function handleOnClick(isSponsored: boolean) {
    hit(
      {
        screen_clickableElementName: `bloc_liste-article${isSponsored ? '_sponso' : ''}`,
      },
      { isClickEvent: true },
    )
  }

  return (
    <>
      <div data-testid="ArticleList" className="ArticleList">
        {title && <Headline htmlText={title} />}
        {elementList.map((element, index) => (
          <ArticleItemComponent
            key={index}
            {...element}
            onClick={() => handleOnClick(!!element.sponsor)}
          />
        ))}
      </div>

      <style jsx>{`
        .ArticleList {
          margin: 0px ${theme.block.marginLR}px 10px;
        }

        .ArticleList :global(.Headline) {
          margin-bottom: 30px;
        }

        .ArticleList :global(article) {
          position: relative;
          margin: 0 0 32px;
        }

        .ArticleList :global(.ArticleItem),
        .ArticleList :global(.ArticleListItem) {
          padding-bottom: 20px;
          border-bottom: 1px solid ${theme.cssVars.lightGray};
        }
      `}</style>
    </>
  )
}
